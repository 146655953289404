import * as React from "react"
import Footer from "../../components/Footer";
import Header from "../../components/Header";

// markup
const PrivacyPage = () => {
  return (
    <main>
        <Header pageName="privacy"/>
        <div>
            <div style={{marginLeft: "auto", marginRight: "auto"}}>
                <h1>FastTravels Privacy Policy</h1>
            </div>
            <div style={{marginLeft: "auto", marginRight: "auto"}}>
                <p>We don't collect or store any personally identifiable information, period. :)</p>
            </div>
        </div>
        <Footer/>
    </main>
  );
}

export default PrivacyPage